import LocomotiveScroll from 'locomotive-scroll';

let lScrollInstance = null;

const init = _ => {
	createInstance();
	bindEvents();
	handleScrollRestoration();
};


const createInstance = _ =>  {
	setTimeout(_ => {
		lScrollInstance = new LocomotiveScroll({ 
			getDirection: true,
			offset: ['15%', 0],
			repeat: true,
			resetNativeScroll: false,
		});
		if (window.matchMedia('(max-width: 991px)').matches) {
			const observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						entry.target.classList.add('is-inview');
					}
				})
			});
			document.querySelectorAll('[data-scroll]').forEach(el => observer.observe(el));
			
		}

	}, 1000);
};


const bindEvents = _ => {
	document.addEventListener('heightChanged', _ => {
		lScrollInstance.update();
	});
};

const handleScrollRestoration = _ => {
	window.addEventListener('unload', _ => localStorage.setItem('scrollY', window.pageYOffset)); 

	if (localStorage.getItem('scrollY')) {
		if (window.location.href === document.referrer) {
			window.scrollTo({
				top: localStorage.getItem('scrollY'),
				behavior: 'instant'
			});
		}
	}
};

export default {
	init: init,
	lScrollInstance: lScrollInstance
};