import cookieHanlder from './cookieHanlder';

let url = '';

const init = _ => {

	const urlInCookie = cookieHanlder.get('firstPageUrl');
	
	if (cookieHanlder.get('firstPageUrl')) {
		url = urlInCookie;
		return;
	}

	const currentUrl = document.location.href;
	cookieHanlder.set('firstPageUrl', currentUrl, { 'max-age': 2592000 });
	url = currentUrl;

};

const getUrl = _ => {
	if (url === '') init();
	return url;
}
 

export default {
	init: init,
	getUrl: getUrl
};