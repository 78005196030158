/**
* LazyLoad
**/

import LazyLoad from 'vanilla-lazyload/dist/lazyload';

const handleEnter = el =>  {
	window.dispatchEvent(new CustomEvent('sliderIsInView', {detail: el.dataset.sliderKey}));
};

const init = _ => {
	const lazyLoadInstance = new LazyLoad({
		elements_selector: '[data-lazy]',
		unobserve_entered: true
	});
	const swiperLazLoadnstance = new LazyLoad({
		elements_selector: '[data-lazy-swiper]',
		unobserve_entered: true,
		threshold: 500,
		callback_enter: handleEnter
	});
	
	document.addEventListener('ll::needUpdate', _ => {
		lazyLoadInstance.update();
	});
};

export default {
	init: init
};