const set = (name, value, options = {}) =>  {
	options = {
		path: '/',
		// add other defaults here if necessary
		...options
	};
  
	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	}
  
	var updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
  
	for (var optionKey in options) {
		updatedCookie += '; ' + optionKey;
		var optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += '=' + optionValue;
		}
	}
  
	document.cookie = updatedCookie;
};

  
const get = name => {
	var matches = document.cookie.match(new RegExp(
		'(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/+^])/g, '\\$1') + '=([^;]*)'
	));
	return matches ? decodeURIComponent(matches[1]) : undefined;
};


export default {
	get: get,
	set: set
};