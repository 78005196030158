import firstUrlHandler from './components/firstUrlHandler';
import marketingUrlHandler from './components/marketingUrlHandler';
import lazyLoadHandler from './components/lazyLoadHandler';
import mobileMenu from './components/mobileMenu';
import scrollHandler from './components/scrollHandler';

const modules = [
	firstUrlHandler,
	marketingUrlHandler,
	lazyLoadHandler,
	mobileMenu,
	scrollHandler

];


document.querySelector('#app').classList.add('is-ready');	

document.addEventListener('DOMContentLoaded', _ => {
	
	modules.forEach(module => module.init());		

});



