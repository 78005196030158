import OneLinkUrlGenerator from './OneLinkUrlGenerator';

let url = '';

const baseOneLinkURL = 'https://hicebank.onelink.me/OMKE/';

const oneLinkParams = {
	pidKeysList: ['af_pid', 'utm_source'],
	pidOverrideList: { 'twitter': 'Twitter_web', 'facebook': 'facebook_web', 'google': 'google_web', 'partners': 'partners_web', 'avito': 'avito', 'cityads': 'cityads_web', 'tiktok': 'tiktok_web', 'lmiro': 'lmiro', 'TrafficJunky': 'trafficjunky_web', 'asata': 'asata', 'katz': 'katz', 'shulman': 'shulman', 'yandex': 'yandex_web', 'litres': 'litres', 'sobchak': 'sobchak', 'bloggers': 'bloggers', 'vk': 'vk', 'mytarget': 'mytarget' },
	pidStaticValue: 'site',
	campaignKeysList: ['af_campaign', 'utm_campaign'],
	campaignStaticValue: 'organic',
	gclIdParam: 'af_sub1',
};

const init = _ => {
	setUrl();
	setUrlToLinks();
};

const setUrl = _ =>  {
	url = generateMarketingUrl(baseOneLinkURL);
}


const generateMarketingUrl = oneLinkURL => {
	const generator = new OneLinkUrlGenerator({
		oneLinkURL: oneLinkURL,
		...oneLinkParams
	});
	generator.setCustomParameter('utm_content', 'af_ad');
	return generator.generateUrl();
};

const setUrlToLinks = _ => {
	document.querySelectorAll('[data-store-link]').forEach(link => {
		link.href = generateMarketingUrl(link.href);
	});
};

const getUrl = _ => {
	if (url === '') setUrl();
	return url;
}

export default {
	init: init,
	getUrl: getUrl
};