/**
* Мобильоное меню
**/

const DOM = {};


const init = _ => {
	if (!window.matchMedia('(max-width: 768px)').matches) return;
	if (!document.querySelector('.header_burger')) return;
	cacheDOM();
	bindEvents();
};

const cacheDOM = _ => {
	DOM.burgerBtn = document.querySelector('.header_burger');
	DOM.menuWrapper = document.querySelector('.header_menu_wrapper');
};

const bindEvents = _ => {
	DOM.burgerBtn.addEventListener('click', handleBtnClick);
};

const handleBtnClick = evt => {
	const { burgerBtn, menuWrapper } = DOM;
	evt.preventDefault();
	burgerBtn.classList.toggle('is-active');
	menuWrapper.classList.toggle('is-active');
};



export default {
	init: init
};